<template>
  <div class="board-view">
    <h1>biardview</h1>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>