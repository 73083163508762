<template>
<div class="create-post">
  <BlogCoverPreview v-show="this.$store.state.blogPhotoPreview" />
  <LoadingView v-show="loading" />
  <div class="container">
    <div :class="{ invisible: !error }" class="err-message">
      <p><span>Error:</span>{{ this.errorMsg }}</p>
    </div>
    <div class="blog-info">
      <input type="text" placeholder="블로그 제목 입력" v-model="blogTitle" />
      <div class="upload-file">
        <label for="blog-photo">표지이미지 선택</label>
        <input type="file" ref="blogPhoto" id="blog-photo" @change="fileChange" accept=".png, .jpg, .jpeg, .gif" />
        <button @click="openPreview" class="preview" 
            :class="{ 'button-inactive' : !this.$store.state.blogPhotoFileURL }">미리보기</button>
        <span>파일 이름: {{ this.$store.state.blogPhotoName }}</span>
      </div>
    </div>
    <div class="editor">
      <vue-editor :editorOptions="editorSettings" v-model="blogHTML" useCustomImageHandler @image-added="imageHandler" />
    </div>
    <div class="blog-actions">
      <button @click="uploadBlog">등록하기</button>
      <!-- <router-link class="router-button" :to="{ name: 'BlogPreview' }">미리보기</router-link> -->
    </div>
  </div>
</div>
</template>
<script>
import Quill from 'quill'
window.Quill = Quill
const ImageResize = require('quill-image-resize-module').default
Quill.register('modules/imageResize', ImageResize)
import db from '../firebase/firebaseInit'
import firebase from 'firebase/app'
import 'firebase/storage'
import BlogCoverPreview from '../components/BlogCoverPreview'
import LoadingView from '../components/LoadingView'

export default {
  name: 'CreatePost',
  components: { BlogCoverPreview, LoadingView },
  data: () => ({
    file: null,
    error: null,
    errorMsg: null,
    loading: null,
    editorSettings: {
      modules: {
        imageResize: {}
      }
    }
  }),
  methods: {
    fileChange() {
      this.file = this.$refs.blogPhoto.files[0]
      const fileName = this.file.name
      this.$store.commit('fileNameChange', fileName)
      this.$store.commit('createFileURL', URL.createObjectURL(this.file))
    },
    openPreview() {
      this.$store.commit('openPhotoPreview')
    },
    imageHandler(file, Editor, cursorLocation, resetUploader) {
      const storageRef = firebase.storage().ref()
        const docRef = storageRef.child(`documents/blogPostPhotos/${file.name}`)
        docRef.put(file).on(
          "state_changed",
          (snapshot) => {
            console.log(snapshot)
          }, (err) => {
            console.log(err)
          }, async () => {
            const downloadURL = await docRef.getDownloadURL()
            Editor.insertEmbed(cursorLocation, "image", downloadURL)
            resetUploader()
          },
        )
      },
      //firestorage에 블로그 이미지저장
      uploadBlog() {          
        if (this.blogTitle.length !== 0 && this.blogHTML.length !== 0) {
          if (this.file) {
              this.loading = true
              const storageRef = firebase.storage().ref()
              const docRef = storageRef.child(`documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`)
              docRef.put(this.file).on(
                "state_changed",
                (snapshot) => {
                console.log(snapshot)
                this.loading = true
              }, (err) => {
                  console.log(err)
                  this.loading = false
              }, async () => {
                  const downloadURL = await docRef.getDownloadURL()
                  const timestamp = await Date.now()  
                  const dataBase = await db.collection('blogPosts').doc()

                await dataBase.set({
                  blogID: dataBase.id,
                  blogHTML: this.blogHTML,
                  blogCoverPhoto: downloadURL,
                  blogCoverPhotoName: this.blogCoverPhotoName,
                  blogTitle: this.blogTitle,
                  profileId: this.profileId,
                  date: timestamp
                })
                  await this.$store.dispatch('getPost')
                  this.loading = false
                  this.$router.push({ name: 'ViewBlog', params: { blogid: dataBase.id } })                       
              }
              )                                          
              return
            }
            this.error = true
            this.errorMsg = '업로드할 사진을 입력하세요!'
            setTimeout(() => {
                this.error = false
            },5000)
            return
        }
        this.error = true;
        this.errorMsg = '제목과 내용을 작성하세요!'
        setTimeout(() => {
          this.error = false
        },5000)
        return
    },
  },
  beforeDestroy()  {
    location.reload()
    },
    computed: {
        profileId() {
          return this.$store.state.profileId
        },
        blogCoverPhotoName(){
          return this.$store.state.blogPhotoName
        },
        blogTitle: {
            get() {
              return this.$store.state.blogTitle
            },
            set(payload) {
              this.$store.commit("updateBlogTitle", payload)
            },
        },
        blogHTML: {
            get() {
              return this.$store.state.blogHTML
            },
            set(payload) {
              this.$store.commit("newBlogPost", payload)
            },
        }
    },
}
</script>

<style lang="scss">
@import '../scss/index';

.create-post {
    margin-top: 3.5em,;
    position: relative;
    height: 100%;
    button {
        margin-top: 0;
    }
    .router-button {
        text-decoration: none;
        color: $color-fff ;
    }
    label,  
    .container {
        position: relative;
        height: 100%;
        padding: 1rem 2.5rem 6rem;
    }
    // error styling
    .invisible {
        opacity: 0 !important;
    }
    .err-message {
        width: 100%;
        padding: 1.2rem;
        border-radius: 0.8rem;
        color: $color-fff;
        margin-bottom: 1rem;
        background-color: $color-303030;
        opacity: 1;
        transition: 0.5s ease all;
        p {
            font-size: 1.4rem;
        }
        span {
            font-weight: 600;
        }
    }
    .blog-info {
        display: flex;
        margin-bottom: 3.2rem;
        input:nth-child(1) {
            min-width: 300px;
        }

        input {
            transition: 0.5s ease-in-out all;
            padding: 1rem 0.4rem;
            border: none;
            border-bottom: 0.1rem solid #303030;
            &:focus {
                outline: none;
                box-shadow: 0 0.1rem 0 0 $color-303030;
            }
        }
        .upload-file {
            flex: 1;
            margin-left: 1.6rem;
            position: relative;
            display: flex;
            input {
                display: none;
            }
            .preview {
                margin-left: 1.6rem;
                text-transform: initial;
            }
            span {
                font-size: 1.2rem;
                margin-left: 1.6rem;
                align-self: center;
            }
        }
    }
    .editor {
        height: 40vh;
        display: flex;
        flex-direction: column;

        .quillWrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 90%;
        }
        .ql-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: scroll;
        }
        .ql-editor {
            padding: 2rem 1.6rem 3rem;
        }
    }
    .blog-actions {
        margin-top: 3.2rem;
        button {
            margin-right: 1.6rem;
        }
    }
}

</style>
