<template>
  <div class="blog-wrapper" :class="{ 'no-user': !user }">
    <div class="blog-content">
        <div>
          <h1 v-if="post.welcomeScreen">{{ post.title }}</h1>            
          <h2 v-else>{{ post.title }}</h2>
          <p v-if="post.welcomeScreen">{{ post.blogPost}}</p>            
          <p class="content-preview" v-else v-html="post.blogHTML"></p>
          <router-link class="link link-light " v-if="post.welcomeScreen" :to="{ name: 'Login' }">로그인/회원가입
            <Arrow class="arrow arrow-light" />
          </router-link>            
          <!-- <router-link class="link" v-else :to="{ name: 'ViewBlog', params: { blogid: this.post.blogID }}">
              게시물 보기<Arrow class="arrow" />
          </router-link>                              -->
        </div>
    </div>
        <div class="blog-photo">
          <img v-if="post.welcomeScreen" :src="require(`../assets/blogPhotos/${post.photo}.jpg`)" alt="Welcome Image" />
          <img v-else :src="require(`../assets/blogPhotos/${post.blogCoverPhoto }.jpg`)" alt="CoverPhoto" />            
            <!-- <img v-else :src="post.blogCoverPhoto" alt="" /> -->
        </div>
  </div>
</template>

<script>
import Arrow from '../assets/Icons/arrow-right-light.svg'
export default {
  name: 'BlogPost',
  props: ['post'],
  components: { Arrow },
  computed: {
    user () {
      return this.$store.state.user
  }
  },  
}
</script>

<style lang="scss" scoped>
@import '../scss/index';
.blog-wrapper {
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow;
    background: radial-gradient(75.61% 75.61% at 50% 24.39%, rgba(224, 234, 252, 0.6)0%, rgba(255, 255, 255, 0.2) 100%);
    @media (min-width: 700px) {
        min-height: 650px;
        max-height: 650px;
        flex-direction: row;        
    }
    .blog-content {     
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 4;
      order: 2;
      animation: slide 2s ease-out;      
        @media (min-width: 700px) {
            order: 1;
        }
         @media (min-width: 800px) {
            flex: 3;
        }
        div {
            max-width: 375px;
            padding: 7.2rem 2.4rem;
           
            @media (min-width: 700px) {
                padding: 0 2.4rem;
                animation: slide 2s ease-out;               
            }
            
            h1 {               
                font-weight: 400;                
                line-height: 1.5rem;
                font-family: "Jua", sans-serif;
                

                @media (min-width: 700px) {
                    font-size: 3rem;
                    line-height: 2rem;
                }               
            }           
            p {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 2.7;   
                margin-top: 2rem;     
                
                                  
            }
            .content-preview {
              font-size: 1.3rem;
              max-height: 6rem;
            //   width: 25rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;            
              
            }
            .link {
              display: inline-flex;
              align-items: center;
              margin-top: 3.2rem;
              padding-bottom: 0.4rem;
              border-bottom: 0.1rem solid transparent;
              transition: 0.5s ease-in all;
              
              &:hover {
                  border-block-color: $color-303030;
              }
            }
            .link-light {              
                &:hover {
                    border-bottom-color: #ffff;
                }               
            }
        }
    }
    .blog-photo {
        order: 1;
        flex: 3;
        box-shadow: $box-shadow;
        animation: slide 2s ease-out;
       

        @media(min-width: 700px) {
            order: 2;
        }

         @media(min-width: 800px) {
            flex: 4;
        }

        img {          
            display: block; 
            width: 100%;
            height: 100%;
            object-fit: cover;
            
        }

    }
    // 이미지 4각형으로 배열
    // .blog-wrapper:nth-child(even) {
    //     .blog-content {
    //         order: 2;
    //     }
    //     .blog-photo {
    //         order: 1;
    //     }
    // }

    &:nth-child(even) {
        .blog-content {
            order: 2;
        }
        .blog-photo {
            order: 1;
        }
    }
}
.no-user:first-child {
    .blog-content {
        background-color: #303030;
        color: #fff;
    }
}
@keyframes slide {
    from {
        left: -10rem;
        opacity: 0;
        color: blue;
    }   
    to {
        left: 15rem;
        opacity: 1;
        color: white;
    }     
}

@keyframes aslide {
    from {
        left: -5rem;
        opacity: 0;
    }   
    to {
        left: -30rem;
        opacity: 1;
    }     
}
</style>