 <template>
  <div class="wrap">  
    <div class="txt1">울산독수리</div>
    <div class="box">
      <div class="login-img">
        <div class="grass"></div>
        <div class="land"></div>
        <div class="vulture"><img src="../assets/black_vulture_1.png" alt=""></div>
        <img src="../assets/tree01.png" class="tree01" alt="">
        <img src="../assets/tree02.png" class="tree02" alt="">
      </div>      
    </div>
    <!-- <img src="../assets/background.png" class="bd" alt=""> -->
</div>
 </template>
 
 <script>
  export default {
    name: 'LoginImg'
  }
 </script>
 
 <style lang="scss" scoped>
 @import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap');
 @import '../scss/reset';
 .bd {
        width: 100%;
        height: 110%;
      }
 .txt1 {
  position: absolute;
  font-family: "Gugi", sans-serif;
  font-size: 25rem;
  font-weight: 700;
  left: 0;
  right: 0;
  top: 65%;
  line-height: 100vh;
  transform: translateY(-50%);
  z-index: 10;
  text-align: center;
  color: #fff;
  // background: #000;
  mix-blend-mode: multiply;
  animation: txt1 1 1s 2.5s both;
  @keyframes txt1 {
    0% { opacity: 0; }
    100% { opacity: 1;}
  }
 }
 .box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: box 1 3s both;
 
  @keyframes box {
    0% { transform: scale(3);}
    100% {transform: scale(1);}
  }
  
  .login-img {
    overflow: hidden;
      width: 100vw;
      height: 100vh;      
      
    
    .grass {
      position: absolute;
      left: 0;
      right: 0;
      top: 70%;
      height: 15rem;
      background: url(../assets/grass.png) repeat-x center top /auto 300%;    
    }
    .land {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10rem;    
      height: 20.7rem;
      background: #000;
    }
    .vulture {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: 48%;
      img {
        width: 40%;
    }
    }
    .tree01 {
      position: absolute;
      left: 0;
      top: 26%;
      width: 40%;
    }
    .tree02 {
      position: absolute;
      right: 0;
      top: 45%;
      width: 30%;
    }
  }
}
 </style>