import Vue from "vue"
import VueRouter from "vue-router"
import firebase from 'firebase/app'
import 'firebase/auth'
import Home from "../views/Home.vue"
import Blogs from "../views/Blogs.vue"
import Login from "../views/Login.vue"
import Register from "../views/Register.vue"
import ForgotPassword from "../views/ForgotPassword.vue"
import Profile from "../views/Profile.vue"
import Admin from "../views/Admin.vue"
import CreatePost from "../views/CreatePost.vue"
import BlogPreview from "../views/BlogPreview.vue"
import ViewBlog from "../views/ViewBlog.vue"
import EditBlog from "../views/EditBlog.vue"
import IntroView from "../components/IntroView.vue"
import LoginImg from "../components/LoginImg.vue"
import BoardView from "../views/board/BoardView.vue"
import AboutUs from "../components/AboutUs.vue"
import DonationUs from "../components/DonationUs.vue"



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Home',
      requiresAuth: false
    }
  },
  {
    path: "/intro",
    name: "Introview",
    component: IntroView,
    meta: {
      title: 'Intro',
      requiresAuth: false
    }
  },  
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    meta: {
      title: '블러그',
      requiresAuth: false
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: '로그인',
      requiresAuth: false
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: 'Register',
      requiresAuth: false
    }
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      title: 'AboutUs',
      requiresAuth: false
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: 'ForgotPassword',
      requiresAuth: true
    }
  },
  {
    path: "/admin20240101",
    name: "Admin",
    component: Admin,
    meta: {
      title: '관리자',
      requiresAdmin: true
      // requiresAuth:	true,
    }	
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: "/create-post",
    name: "CreatePost",
    component: CreatePost,
    meta: {
      title: 'Create Post',
      requiresAdmin: true
      // requiresAuth: true
    }
  },
  {
    path: "/post-preview",
    name: "BlogPreview",
    component: BlogPreview,
    meta: {
      title: 'Preview Blog Post',
      requiresAdmin: true
      // requiresAuth: true
    }
  },
  {
    path: "/view-blog/:blogid",
    name: "ViewBlog",
    component: ViewBlog,
    meta: {
      title: 'View Blog Post',
      requiresAuth: false,
    }
  },
  {
    path: "/edit-blog/:blogid",
    name: "EditBlog",
    component: EditBlog,
    meta: {
      title: 'Edit Blog',
      requiresAdmin: true
      // requiresAuth: true
    }
  },
  {
    path: "/loginimg",
    name: "LginImg",
    component: LoginImg,
    meta: {
      title: 'LoginImg',
      requiresAuth: false      
    }
  },
  {
    path: "/boardview",
    name: "BoardView",
    component: BoardView,
    meta: {
      title: 'BoardView',
      requiresAuth: false      
    }
  },
  {
    path: "/donation",
    name: "DonationUs",
    component: DonationUs,
    meta: {
      title: 'Donation',
      requiresAuth: false      
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior() {
    return { left:0, top: 0 }  
  } 
});
// const router = createRouter({
//   scrollBehavior(to, from, savedPosition) {
//     // always scroll to top
//     return { top: 0 }
//   },
// })

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 울산독수리`
  next()
})
router.beforeEach(async (to, from, next) => {
  let user = firebase.auth().currentUser
  let admin = null
  if (user) {
    let token = await user.getIdTokenResult()
    admin = token.claims.admin  
  }
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user) {
      if (to.matched.some((res) => res.meta.requiresAdmin)) {
        if (admin) {
          return next()
        }
        return next({ name: "Home" })
      }
       return next() 
    }
    return next({ name: "Home" })
  }
    return next()
})
export default router
