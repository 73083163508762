import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'
import db from '../firebase/firebaseInit'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    welcomeScreen: {
      title: 'Welcome!',
      blogPost:'울산독수리의 힘찬 비상~~ 천연기념물 제234호\n 환경부지정 멸종위기 2급' ,      
      photo: 'nn',
      welcomeScreen: true
    },
    sampleBlogPost: [
      {
        title: "독수리의 급강하 착지",
        blogHTML: "무리들의 먹이활동에 늦은 독수리,<br> 독수리식당에 급하게 착륙하고 있음 ",
        blogCoverPhoto: "down2"
      },
      {
        title: "66번 대박이 방사",
        blogHTML: "탈진, 농약 중독으로 치료후 방사",
        blogCoverPhoto: "out"
      },
       {
        title: "'KM' tag",
        blogHTML: "까치, 까마귀의 텃새에 둘러쌓여있는 독수리,<br> 때로는 공격당하기도 합니다.",
        blogCoverPhoto: "km"
      },
      {
        title: "공격당하는 독수리",
        blogHTML: "텃새인 큰부리까마귀와 까치 무리에게 <br>공격당하는 독수리",
        blogCoverPhoto: "221216"
      },
      {
        title: "'MN' tag 독수리",
        blogHTML: "독수리식당의 주변을 돌면서 비행하는 독수리",
        blogCoverPhoto: "mn_5000"
      },
      {
        title: "이크나크 자연보호구",
        blogHTML: "몽골의 주요 조류 서식지로서 국내에서 월동하는 <br>많은 독수리가 이곳에서 서식하고 있음.",
        blogCoverPhoto: "ikhnart"
      },
      {
        title: "독수리 이동경로",
        blogHTML: "GPS 위성추적경로<br> 몽골 이크나크 자연보호구 도착 ",
        blogCoverPhoto: "path"
      },
      {
        title: "독수리 심포지움",
        blogHTML: "한반도 독수리 보전을 위한 네트워크 구축<br>울산, 철원, 파주, 경남 고성, 김해",
        blogCoverPhoto: "metting"
      },
    ],
    blogPosts: [],
    postLoaded: null,
    blogHTML: '내용을 작성해주세요🌿🍀', 
    blogTitle: '',
    blogPhotoName: '',
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
    editPost: null,
    user: null,
    profileAdmin: null,
    profileEmail: null,    
    profileUsername: null,
    profileId: null,
    profileInitials: null
  },
  getters: {
    welcomeScreen(state) {
      return state.welcomeScreen
    },
    sampleBlogPost(state) {
      return state.sampleBlogPost
    },
    blogPostsFeed(state) {
      return state.blogPosts.slice(0, 2)
    },
    blogPostsCards(state) {
      return state.blogPosts.slice(0, 10)
    },
  },
  mutations: {
    newBlogPost(state, payload) {
      state.blogHTML = payload
      // console.log(state.blogHTML)
    },
    updateBlogTitle(state, payload) {
      state.blogTitle = payload
    },
    fileNameChange(state, payload) {
      state.blogPhotoName = payload
    },
    createFileURL(state, payload) {
      state.blogPhotoFileURL = payload
    },
    openPhotoPreview(state) {
      state.blogPhotoPreview = !state.blogPhotoPreview
    },
    toggleEditPost(state, payload) { 
      state.editPost = payload
      // console.log(state.editPost);
    },
    setBlogState(state, payload) {
      state.blogTitle = payload.blogTitle
      state.blogHTML = payload.blogHTML
      state.blogPhotoFileURL = payload.blogCoverPhoto
      state.blogPhotoName = payload.blogCoverPhotoName
    },
    filterBlogPost(state, payload) {
      state.blogPosts = state.blogPosts.filter((post) => post.blogID !== payload)
    },   
    updateUser(state, payload) { 
      state.user = payload
    },
    setProfileAdmin (state, payload) {
      state.profileAdmin = payload
      console.log(state.profileAdmin)
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id
      state.profileEmail = doc.data().email
      state.profileUsername = doc.data().username
    },
    setProfileInitials(state) { 
      const str = state.profileUsername      
      const trimStr = str.split(' ').join('')
      state.profileInitials = trimStr.substr(0,1)
      // state.profileInitials = state.profileUsername.substr(0,1)
      // state.profileInitials = state.profileFirstName.match(/(\b\S)?/g).join("") +
      // state.profileLastName.match(/(\b\S)?/g).join("");
      // state.profileInitials = state.profileFirstName.match(/^[가-힣]{2,5}$).join("") +
      // state.profileLastName.match(^[가-힣]{2,5}$).join("")
      },
      // changeFirstName(state, payload) {
      //   state.profileFirstName = payload;
      // },
      // changeLastName(state, payload) {
      //   state.profileLastName = payload;
      // },
      changeUsername(state, payload) {
        state.profileUsername = payload
      },
  },
  actions: {
    async getCurrentUser({ commit }, user) {   
      const dataBase = await db.collection('users').doc(firebase.auth().currentUser.uid)
      const dbResults = await dataBase.get()
      commit('setProfileInfo', dbResults)
      commit('setProfileInitials')
      const token = await user.getIdTokenResult()
      const admin = await token.claims.admin
      commit('setProfileAdmin', admin)
      // console.log(dbResults, admin)
    },
    async getPost({ state }) {
      const dataBase = await db.collection('blogPosts').orderBy('date', 'desc')
      const dbResults = await dataBase.get()
      dbResults.forEach((doc) => {
        if (!state.blogPosts.some((post) => post.blogID === doc.id)) {
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
            blogPhotoFileURL: doc.data().blogCoverPhoto
          }
          state.blogPosts.push(data)
        }
      });
      state.postLoaded = true
      // console.log(state.blogPosts)    
    },
    async updatePost ({ commit, dispatch }, payload) {
      commit('filterBlogPost', payload)
      await dispatch('getPost')
    },   
    async deletePost ({ commit }, payload) {
      const getPost = await db.collection('blogPosts').doc(payload)
      await getPost.delete()
      commit('filterBlogPost', payload)
    },
    
    async updateUserSettings ({ commit, state }) {
      const dataBase = await db.collection('users').doc(state.profileId)
      await dataBase.update({
          username: state.profileUsername
      });
      commit('setProfileInitials')
    },

  },
  modules: {
  }
})
