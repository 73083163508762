import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Vue2Editor from "vue2-editor"
import firebase from 'firebase/app'
import 'firebase/auth'
import AOS from 'aos'
import 'aos/dist/aos.css'
import dotenv from 'dotenv'

dotenv.config()

Vue.use(Vue2Editor)

Vue.config.productionTip = false

let app
firebase.auth().onAuthStateChanged(() => {  
  if (!app) {   
    new Vue({
      created() {
        AOS.init({
          duration: 300
        })
      },
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});


