<template>
  <div>
    <h1>intro</h1>
  </div>
</template>

<script>

export default {
    name: 'IntroView'    
}
</script>

<style lang="scss" scoped>

</style>