<template>
    <div class="form-wrap">
        <form class="login">
            <p class="login-register">계정이 없으십니까?
                <router-link class="router-link forgot-password" 
                  style="margin-left: 1rem
                  ;"
                    :to="{ name: 'Register' }">회원가입
                </router-link>
            </p>
            <h2>로그인</h2>
            <div class="inputs">
                <div class="input">
                    <input type="email" placeholder="이메일" v-model="email" autofocus />
                    <email class="icon" />
                </div>            
                <div class="input">
                    <input type="password" placeholder="비밀번호" v-model="password" autofocus />
                    <password class="icon" />
               </div>
                <div v-show="error" class="error">{{ this.errorMsg }}</div>
            </div>
            <router-link class="forgot-password" 
              :to="{ name: 'ForgotPassword' }"> 비밀번호를 잃어버렸습니까?
            </router-link>
            <Loading v-if="loading" />
            <button @click.prevent="signIn">로그인</button>
            <div class="angle"></div>
        </form>
        <div class="background"></div>
         <!-- <LoginImg  class="login-img"/> -->
    </div>
</template>
<script>
import email from '../assets/Icons/envelope-regular.svg'
import password from '../assets/Icons/lock-alt-solid.svg'
import Loading from '../components/LoadingView.vue'

import firebase from 'firebase/app'
import 'firebase/auth'

export default {
    name: 'Login',
    components: { email, password, Loading },
    data (){
      return {
        email: '',
        password: '',
        error: null,
        loading: false,
        errorMsg: ''
      }
    },
    methods: {
      signIn() {
        this.loading = true
        firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push({ name: 'Home'})
          this.error = false
          this.errorMsg =""
          // console.log(firebase.auth().currentUser.uid)         
          this.loading = false       
        }).catch((err) => {
          this.error = true
          this.errorMsg = err.message
          this.loading = false
        })
    }
    }
}
</script>

<style lang="scss">
@import '../scss/index'; 

.form-wrap {
    overflow: hidden;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    width: 90%;    
        
    @media (max-width: 899px) {
      background: linear-gradient(180deg, #f1f1f1 0%, rgba(255,255,255,0) 189%);
    }   
    @media (min-width: 900px) {
        width: 100%;
    }   
    .login-register {
        margin-bottom: 3.2rem;

        .router-link {
            color: #000;          
        }        
    }
    form {
        padding: 0 1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
       
        @media (min-width: 900px) {
            padding: 0 50px;
        }
        h2 {
            text-align: center;
            font-size: 3.2rem;
            color: #303030;
            font-family: "Noto Sans KR", sans-serif;
            margin-bottom: 4rem;
            @media (min-width: 900px) {
                font-size: 4rem;
            }
        }
        p {
            font-family: "Noto Sans KR", sans-serif;
            font-weight: bold;
        }
       
        .inputs {
            width: 100%;
            max-width: 350px;

            .input {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0.8rem;
                input {
                    width: 100%;
                    border: none;
                    background-color: #f2f7f6;
                    padding: 0.4rem 0.4rem 0.4rem 3rem;
                    height: 5rem;

                    &:focus {
                        outline: none;
                    }
                }
                .icon {
                    width: 1.2rem;
                    position: absolute;
                    left: 0.6rem;
                }
            }
        }
        .forgot-password {
            text-decoration: none;
            color: #000;
            cursor: pointer;
            font-size: 1.4rem;
            margin: 1.6rem 0 3.2rem;
            border-bottom: 0.1rem solid transparent;
            transition: 0.5s ease all;         
            font-family: "Noto Sans KR", sans-serif;
            font-weight: bold;       
            &:hover {
              border-color: #303030;
            }
        }
        .angle {
          display: none;
          position: absolute;
          background-color: #fff;
          transform: rotateZ(3deg);
          width: 6rem;
          right: -3rem;
          height: 101%;
          @media (min-width: 900px) {
            display: initial;
          }
        }
    }
    .background {
        display: none;        
        flex: 2;
        background-size: 1000px 800px;        
        // background-image: url('../assets/background.png');
        // background-image: url('../assets/blogPhotos/group_efect.png');       
        background-image: url('../assets/black_vulture.png');     
        filter: blur(2px);     
        &:hover {
            filter: blur(0);
        }
          
        width: 100%;
        height: 100%;
        // animation: box 1 3s both;
        // @keyframes box {
        //     0% { transform: scale(3);}
        //     100% {transform: scale(1);}            
        //  }
   
        @media (min-width: 900px) {
            display: initial;            
        }
        @media (min-width:901px) and (max-width: 1500px) {                 
                filter: blur(2px);    
                max-width: 600px;
                width: 60%;
                height: 100%;   
                &:hover {
                  filter: blur(0);
                }           
        }
        // .login-img {
        //     display: none;
        //     flex: 2;
        //     background-size: cover;
        //     // background-image: url('../assets/background.png');
        //     width: 100%;
        //     height: 100%;
        //     @media (min-width: 900px) {
        //         display: initial;
        //     }
        //     }
    }
}
</style>