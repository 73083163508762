<template>
  <div class="blog-card">
    <div v-show="editPost" class="icons">
      <div @click="editBlog" class="icon">
        <Edit class="edit" />
      </div>
      <div @click="deletePost" class="icon">
        <Delete class="delete" />
      </div>
     </div>
    <img :src="post.blogCoverPhoto" alt="Cover Photo" />
    <div class="info">
      <h4>{{ post.blogTitle }}</h4>
      <h6>게시일: {{ new Date(post.blogDate).toLocaleString('ko-KR', { dateStyle: 'long'}) }}</h6>      
      <router-link class="link" :to="{ name: 'ViewBlog', params: { blogid: this.post.blogID }}">
        상세보기<Arrow class="arrow" />
      </router-link>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/storage'
import Arrow from '../assets/Icons/arrow-right-light.svg'
import Edit from '../assets/Icons/edit-regular.svg'
import Delete from '../assets/Icons/trash-regular.svg'

export default {
  name: 'BlogCard',
  props: ['post'], //blogs
  components: { Arrow, Edit, Delete }, 
  methods: {
    deletePost() {
      if ( confirm('정말 삭제하시겠습니까?') ==  true) {              
        const storageRef = firebase.storage().ref()
        // const docRef = storageRef.child('this.post.blogCoverPhoto')    
        // (`documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`)
        const docRef = storageRef.child(`documents/BlogCoverPhotos/${this.post.blogCoverPhotoName}`)   
       
        docRef.delete()
        this.$store.dispatch('deletePost', this.post.blogID)                          
       
      } else { alert('삭제가 취소되었습니다.')}
    },
    editBlog() {
      this.$router.push({ name: 'EditBlog', params: { blogid: this.post.blogID } })
    },
  },
  computed: {
    editPost() {
      return this.$store.state.editPost
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variable';
.blog-card {
  position: relative;  
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: $color-fff;
  min-height: 420px;
  transition: 0.5s ease all;
  margin-top: 2rem;

  &:hover {
    transform: rotateZ(-1deg) scale(1.05);
    box-shadow: $box-shadow;
  }

  .icons {
    display: flex;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 99;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: $color-fff;
      transition: 0.5s ease all;

      &:hover {
        background-color: $color-303030;

        .edit,
        .delete {
          path {
            fill: $color-fff;
          }          
        }
      }
    &:nth-child(1) {
      margin-right: 0.8rem;
    }

    .edit,
    .delete {
      pointer-events: none;
      height: 1.5rem;
      width: auto;
    }
  }
  }

  img {
    display: block;
    border-radius: 0.8rem 0.8rem 0 0;
    z-index: 0;
    width: 100%;
    min-height: 25rem;
    object-fit: cover;
  }

  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    padding: 3.2rem 1.6rem;
    color: #000;

    h4 {
      padding-bottom: 0.8rem;
      font-size: 2rem;
      font-weight: 300;
    }
    h6 {
      font-weight: 400;
      font-size: 1.2rem;
      padding-bottom: 1.6rem;      
    }
    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;      
      font-weight: 500;
      padding-top: 2rem;
      font-size: 1.3rem;
      padding-bottom:0.4rem;
      transition: 0.5s ease-in all;

      &:hover {
        color: rgba(48,48,48,0.8);
      }
      .arrow {
        width: 1rem;
      }
    }
  }
}
</style>
