<template>
  <div class="blog-card-wrap">
    <div class="blog-cards container">
      <div v-show="user" class="toggle-edit">
        <span>편집하기</span>
        <input type="checkbox" v-model="editPost" />
      </div>
        <BlogCard :post='post' v-for="(post, index) in blogPosts" :key="index" />
    </div>
  </div>
</template>

<script>
import BlogCard from '../components/BlogCard.vue'

export default {
    name: 'Blogs',
    components: { BlogCard },
    computed: {
        user() {
          return this.$store.state.user
        },
        blogPosts() {
          return this.$store.state.blogPosts
        },
        editPost: {
          get() {
            return this.$store.state.editPost
          },
          set(payload) {
            this.$store.commit('toggleEditPost', payload)
          }
        }
    },
    beforeDestroy()  {
        this.$store.commit('toggleEditPost', false)
    }
}
</script>

<style lang="scss" scoped>
@import '../scss/index';
.blog-card-wrap{
  margin-top: 1rem;
.blog-cards {
    position: relative;
    margin-top: 14rem;   

    .toggle-edit {
        display: flex;       
        margin: 0 2rem 0 0;
        align-items: center;
        position: absolute;
        top: -3rem;
        right: 0;

        span {
            margin-right: 1.6rem;
        }
        input[type='checkbox'] {
            position: relative;
            border: none;
            -webkit-appearance: none;
            background: #b7b3b3;
            outline: none;
            width: 8rem;
            height: 3rem;
            border-radius: 2rem;
            box-shadow: $box-shadow;            
        }

        input[type='checkbox']:before {
            content: '';
            position: absolute;
            width: 3rem;
            height: 3rem;
            border-radius: 2rem;
            top: 0;
            left: 0;
            background: #303030;
            transform: scale(1.1);
            transition: 750ms ease all;
            box-shadow: $box-shadow ;
        }
        input:checked[type='checkbox']:before {
            background: #fff;
            left: 5.2rem;
        }
        }
    }
}


</style>