<template>
    <div class="profile">
        <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal" />
        <div class="container">
            <h2>회원정보 수정</h2>
            <div class="profile-info">
                <div class="initials">{{ $store.state.profileInitials }}</div>
                <div class="admin-badge">
                    <adminIcon class="icon" />
                    <span>관리자</span>
                </div>               
                <div class="input">
                    <label for="username">사용자이름:</label>
                    <input type="text" id="username" v-model="username" />
                </div>
                <div class="input">                   
                  <label for="email">이메일:</label>
                  <input disabled type="text" id="email" v-model="email"/>                        
                  <span class="input-notice">'이메일은 수정이 되지 않습니다.</span>
                </div>
                
                <button @click="updateProfile">변경</button>
            </div>
        </div>
    </div>
</template>

<script>
// import ModalView from '../components/ModalView'
import adminIcon from '../assets/Icons/user-crown-light.svg'

export default {
    name: 'Profile',
    components: { adminIcon },
    data () {
        return {
            modalMessage: '변경되었습니다!',
            modalActive: null,
            noticeMsg:''            
        }
    },
    methods: {
        updateProfile() {
            if (confirm('수정하시겠습니까?') == true) {
                this.$store.dispatch('updateUserSettings')
                this.modalActive = !this.modalActive
            } else { alert('수정이 취소되었습니다.') }
        },
        closeModal() {
            this.modalActive = !this.modalActive
        },
        notice() {
            return this.noticeMsg = '이메일은 수정이 되지 않습니다.'                        
        }             
    },
    computed: {      
        username: {
            get () {
                return this.$store.state.profileUsername
            },
            set (payload) {
                this.$store.commit('changeUsername', payload)
            }
        },
        email () {
          return this.$store.state.profileEmail
        }              
    }
}
</script>

<style lang="scss" scoped>
.profile {  
  .container {
       max-width: 1000px;
      padding: 60px 25px;

      h2 {
          text-align: center;
          margin-bottom: 16px;
          font-weight: 300;
          font-size: 32px;
      }

      .profile-info {
          border-radius: 8px;
          box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
          padding: 32px;
          background-color: #f1f1f1;
          display: flex;
          flex-direction: column;
          max-width: 600px;
          margin: 32px auto;
          .input-notice {
              color: red;               
              opacity: 0;
              &:hover {
                opacity: 1;               
            }
              
          }

          .initials {
              position: initial;
              width: 80px;
              height: 80px;
              font-size: 32px;
              background-color: #303030;
              color: #fff;
              display: flex;
              align-self: center;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
          }
          .admin-badge {
              display: flex;
              align-self: center;
              color: #fff;
              font-size: 14px;
              padding: 8px 24px;
              border-radius: 8px;
              background-color: #303030;
              margin: 16px 0;
              text-align: center;
              text-transform: capitalize;
              .icon {
                  width: 14px;
                  height: auto;
                  margin-right: 8px;
              }
          }
          .input {
              margin: 16px 0;

              label {
                  font-size: 14px;
                  display: block;
                  padding-bottom: 6px;
              }              
              input {
                  width: 100%;
                  border: none;
                  background-color: #f2f7f6;
                  padding: 8px;
                  height: 50px;
                  @media (min-width: 900px) {
              }                                
              &:focus {
                  outline: none
              }
          }           
      }
      button {
          align-self: center;
      }
      }
  }
    
}

</style>