<template>
    <div class="create-post">
        <BlogCoverPreview v-show="this.$store.state.blogPhotoPreview" />
        <LoadingView v-show="loading" />
        <div class="container">
            <div :class="{ invisible: !error }" class="err-message">
                <p><span>Error:</span>{{ this.errorMsg }}</p>
            </div>
            <div class="blog-info">
                <input type="text" placeholder="블로그 제목 입력" v-model="blogTitle" />
                <div class="upload-file">
                    <label for="blog-photo">사진 올리기</label>
                    <input type="file" ref="blogPhoto" id="blog-photo" @change="fileChange" accept=".png, .jpg, .jpeg, gif" />
                    <button @click="openPreview" class="preview" :class="{ 'button-inactive' : !this.$store.state.blogPhotoFileURL }">사진 미리보기</button>
                    <span>파일 선택: {{ this.$store.state.blogPhotoName }}</span>
                </div>
            </div>
            <div class="editor">
                <vue-editor :editorOptions="editorSettings" 
                    v-model="blogHTML" 
                    useCustomImageHandler 
                    @image-added="imageHandler" 
                />
            </div>
            <div class="blog-actions">
                <button @click="updateBlog">수정하기</button>
                <router-link class="router-button" :to="{ name: 'BlogPreview'}">미리보기</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import BlogCoverPreview from '../components/BlogCoverPreview';
import LoadingView from '../components/LoadingView';
import firebase from 'firebase/app';
import 'firebase/storage';
import db from '../firebase/firebaseInit';
import Quill from 'quill';
window.Quill = Quill;
const ImageResize = require('quill-image-resize-module').default;
Quill.register('modules/imageResize', ImageResize);

export default {
    name: 'CreatePost',
    components: {
        BlogCoverPreview,
        LoadingView
    },
    data: () => ({
        file: null,
        error: null,
        errorMsg: null,
        loading: null,
        routeID: null,
        currentBlog: null,
        editorSettings: {
            modules: {
                imageResize: {},
            }
        }
    }),   
    async mounted() {
        this.routeID = this.$route.params.blogid
        this.currentBlog = await this.$store.state.blogPosts.filter(post => {
            return post.blogID === this.routeID
        })
        this.$store.commit('setBlogState', this.currentBlog[0])
    },
    methods: {
        fileChange() {
            this.file = this.$refs.blogPhoto.files[0]
            const fileName = this.file.name
            this.$store.commit('fileNameChange', fileName)
            this.$store.commit('createFileURL', URL.createObjectURL(this.file))
        },
        openPreview() {
            this.$store.commit('openPhotoPreview')
        },
        imageHandler(file, Editor, cursorLocation, resetUploader) {
            const storageRef = firebase.storage().ref()
            const docRef = storageRef.child(`documents/blogPostPhoto/${file.name}`)
            docRef.put(file).on(
                "state_changed",
                (snapshot) => {
                    console.log(snapshot)
                }, (err) => {
                    console.log(err)
                }, 
                async () => {
                    const downloadURL = await docRef.getDownloadURL()
                    Editor.insertEmbed(cursorLocation, "image", downloadURL)
                    resetUploader()
                },
            )
        },
        //firestorage에 블로그 이미지저장
        async updateBlog() {
            const dataBase = db.collection("blogPosts").doc(this.routeID)
            if (confirm ('정말 수정하시겠습니까?') === true ) {
                if (this.blogTitle.length !== 0 && this.blogHTML.length !== 0) {
                    if (this.file) {
                        this.loading = true
                        const storageRef = firebase.storage().ref()
                        const docRef = storageRef.child(`documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`)
                        docRef.put(this.file).on(
                            "state_changed",
                            (snapshot) => {
                            console.log(snapshot)
                        },(err) => {
                            console.log(err)
                            this.loading = false
                        }, 
                        async () => {
                            const downloadURL = await docRef.getDownloadURL()
                            // const timestamp = await Date.now();
                            // const dataBase = await db.collection("blogPosts").doc();

                            await dataBase.update({
                                blogHTML: this.blogHTML,
                                blogCoverPhoto: downloadURL,
                                blogCoverPhotoName: this.blogCoverPhotoName,
                                blogTitle: this.blogTitle,
                            });
                            await this.$store.dispatch('updatePost', this.routeID)
                            this.loading = false
                            this.$router.push({ name: 'ViewBlog', params: { blogid: dataBase.id } })
                        }
                        )
                        return;
                    }
                    this.loading = true
                    await dataBase.update({
                        blogHTML: this.blogHTML,
                        blogTitle: this.blogTitle
                    });
                    await this.$store.dispatch('updatePost', this.routeID)
                    this.loading = false
                    this.$router.push({ name: "ViewBlog", params: { blogid: dataBase.id }})
                    return
                }
                this.error = true
                this.errorMsg = '제목과 내용을 작성하세요!'
                setTimeout(() => {
                    this.error = false
                },5000)
                return
        } else { alert ('수정이 취소되었습니다.')}
        },
    },
    // beforeDestroy()  {
    //     this.$store.commit('toggleEditPost', false)
    // },
    computed: {
        profileId() {
            return this.$store.state.profileId;
        },
        blogCoverPhotoName(){
            return this.$store.state.blogPhotoName;
        },
        blogTitle: {
            get() {
                return this.$store.state.blogTitle;
            },
            set(payload) {
                return this.$store.commit("updateBlogTitle", payload);
            },
        },
        blogHTML: {
            get() {
                return this.$store.state.blogHTML;
            },
            set(payload) {
                return this.$store.commit("newBlogPost", payload);
            },
        }
    },
}
</script>

<style lang="scss">
.create-post {
    position: relative;
    height: 100%;

    button {
        margin-top: 0;
    }

    .router-button {
        text-decoration: none;
        color: #fff;
    }

    label,
    button,
    .router-button {
        transition: 0.5s ease-in-out all;
        align-self: center;
        font-size: 14px;
        cursor: pointer;
        border-radius: 20px;
        padding: 12px 24px;
        color: #fff;
        background-color: #303030;
        text-decoration: none;

        &:hover {
            background-color: rgba(48,48,48,0.7);
        }
    }
    .container {
        position: relative;
        height: 100%;
        padding: 10px 25px 60px;
    }
    // error styling
    .invisible {
        opacity: 0 !important;
    }

    .err-message {
        width: 100%;
        padding: 12px;
        border-radius: 8px;
        color: #fff;
        margin-bottom: 10px;
        background-color: #303030;
        opacity: 1;
        transition: 0.5s ease all;
    }
    .blog-info {
        display: flex;
        margin-bottom: 32px;

        input:nth-child(1) {
            min-width: 300px;
        }

        input {
            transition: 0.5s ease-in-out all;
            padding: 10px 4px;
            border: none;
            border-bottom: 1px solid #303030;
        }
        .upload-file {
            flex: 1;
            margin-left: 16px;
            position: relative;

            input {
                display: none;
            }

            .preview {
                margin-left: 16px;
                text-transform: initial;
            }
            span {
                font-size: 12px;
                margin-left: 16px;
                align-self: center;
            }
        }
    }
    .editor {
        height: 60vh;
        display: flex;
        flex-direction: column;

        .quillWrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .ql-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: scroll;
        }

        .ql-editor {
            padding: 20px 16px 30px;
        }
    }

    .blog-actions {
        margin-top: 32px;

        button {
            margin-right: 16px;
        }
    }
}

</style>