<template>
    <div class="reset-password">
        <ModalView v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal='closeModal' />
        <LoadingView v-if="loading" />
        <div class="form-wrap">
        <form class="reset">
             <p class="login-register">돌아 가기
                <router-link class="router-link" 
                    :to="{ name: 'Login'}">로그인
                </router-link>
            </p>
            <h3>비밀번호 초기화</h3><br>
            <p>비밀번호를 잃어버렸습니까?<br><br> 이메일을 입력하세요</p>
            <div class="inputs">
                <div class="input">
                <input type="email" placeholder="이메일" v-model="email" />
                <email class="icon" />
                </div>
            </div>
            <button @click.prevent="resetPassword">초기화</button>
            <div class="angle"></div>
        </form>
        <div class="background"></div>
        </div>
    </div>
</template>

<script>
import email from '../assets/Icons/envelope-regular.svg'
import ModalView from '../components/ModalView'
import LoadingView from '../components/LoadingView'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: null,
      modalActive: false,
      modalMessage: '',
      loading: false
  }
  },
  components: { email, ModalView, LoadingView },
    methods: {
        resetPassword() {
            this.loading = true
            firebase
            .auth()
            .sendPasswordResetEmail(this.email)
            .then(() => {
                this.modalMessage = '등록된 이메일로 비밀번호를 보냈습니다'
                this.loading = false
                this.modalActive = true
            }).catch(err => {
                this.modalMessage = err.message 
                this.loading = false
                this.modalActive = true
            })
        },
        closeModal() {
            this.modalActive = !this.modalActive
            this.email = ''
        }
    }
}
</script>

<style lang="scss" scoped>
.reset-password {
  position: relative;
  .form-wrap {
    .reset {
      h2 {
        margin-bottom: 8px;
      }
      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}
</style>