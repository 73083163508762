<template>
  <div class="about" id="header">
    <!-- <div class="dimmed"></div> -->
    <main>
      <div class="visual">
        <div class="sns">
          <span class="sns-title" data-aos="fade-down" data-aos-delay="500">Follow us</span>
          <!-- <a href="" aria-label="밴드"><band class="btn-band" /></a> -->
          <a href="https://band.us/n/aca9AfC9b4P1U" aria-label="밴드" target="_blank"><band class="btn-band" /></a>
          <a href="https://youtube.com/@tv-qi4qv?si=JMgOHrTb-yUilbEm" aria-label="유튜브" target="_blank"><youtube class="btn-youtube" /></a>
        </div>
        <div class="visual-content">
          <div class="subtitle">녹색에너지시민촉진포럼</div>
          <div class="maintitle">다음세대를 위한 지속가능한발전<br> 녹색에너지운동이 함께합니다!</div>
          <div class="text-guide" @click="downBtn">Scroll Down<arrow class="arrow" /></div>
        </div>    
        <nav class="quick">
          <ul>            
            <li><a href="#header" :class="{active: isStart}" @click="chk00()">Start</a></li>
            <li><a href="#block1" :class="{active: is01}" @click="chk01()">01</a></li>
            <li><a href="#block2" :class="{active: is02}" @click="chk02()">02</a></li>
            <li><a href="#block3" :class="{active: is03}" @click="chk03()">03</a></li>          
            <li><a href="#block4" :class="{active: is04}" @click="chk04()">04</a></li>          
            <li><a href="#block5" :class="{active: is05}" @click="chk05()">05</a></li>          
          </ul>
        </nav>
      </div>  
    <img src="../assets/etc/HG1.png" class="bg-img1">
    <img src="../assets/etc/mountin.png" class="bg-img2">
    <img src="../assets/etc/front.png" class="bg-img3">
    <div class="gradient-content"></div>    
    <section class="index-contents">
      <div class="block" id="block1">
        <div data-aos="fade-right" >
        <h2 class="block-title">기후변화 대응을 위한 글로벌 네트워크</h2>
        <span class="block-count">01</span>
        <span class="block-subtitle">네트워크</span>  
        <p class="block-content">
          UN경제사회이사회(ECOSOC) DESA NGO Branch/ CSO 등록
          2011년 8월, 녹색에너지포럼은 UN경제사회이사회(ECOSOC)
          DESA NGO Branch와 CSO에 각각 등록하면서  UN경제사회이사회
          (ECOSOC) 특별자문 지위를 갖는 GNGO의 지위를 획득
        </p>
        <router-link to="/aboutus" class="block-more">read more</router-link>
        </div>
        <img src="../assets/etc/ECOSOC.jpg"  data-aos="fade-left" alt="">
      </div>
      <div class="block" id="block2">
        <div data-aos="fade-left" >
          <h2 class="block-title">정책개선및 연구활동</h2>
          <span class="block-count">02</span>
          <span class="block-subtitle">연구활동</span>  
          <p class="block-content">
            기후변화협약대응, 생물다양성, 지속가능한개발목표(SDGs)
            달성을 위한 시민사회의 다양한 의견과 주제를 연구하며 이를
            정책의제로 설정하기 위한 활동을 진행합니다.
          </p>
          <router-link to="/aboutus" class="block-more">read more</router-link>
       </div>
        <img src="../assets/etc/engineering_566x720.jpg" data-aos="fade-right"  alt="">
      </div>
      <div class="block" id="block3">
        <div data-aos="fade-right"  >
        <h2 class="block-title">녹색에너지 교육활동</h2>
        <span class="block-count">03</span>
        <span class="block-subtitle">교육활동</span>  
        <p class="block-content">
          녹색에너지체험학교와 탄소중립행사 운영 일상적인 행사에서 발생하는
          온실가스를 상쇄하는 탄소중립운동을 실천하고 있습니다. '에너지의날'과
          '녹색생활실천전국대회'를 개최하면서 생활속 '탄소중립'의 중요성을 
          강조하고 그린스타트네트워크와 함께 저탄소녹색성장을 위한 시민실천
          운동에 앞장서고 있습니다.
       </p>
       <router-link to="/aboutus" class="block-more">read more</router-link>
      </div>
        <img src="../assets/etc/교육_566x720.jpg" data-aos="fade-left">
      </div>
      <div class="block" id="block4">
        <div data-aos="fade-left" >
        <h2 class="block-title">신재생에너지및 '사랑의햇빛에너지 보급사업'</h2>
        <span class="block-count">04</span>
        <span class="block-subtitle">보급사업</span>  
        <p class="block-content">
          KBS울산방송국과 공동으로 지역의 에너지빈곤층 대상을 파악하고
          에너지빈곤층의 에너지자립을 위해 2.5KW 태양광발전을 무상으로
          지원해 에너지빈곤층 문제해결과 지역사회의 신재생에너지보급과
          지속가능한 에너지문제 해결을 위한 발판을 마련하였습니다.
       </p>
       <router-link to="/aboutus" class="block-more">read more</router-link>
      </div>
        <img src="../assets/etc/태양광566x720.jpg" alt="" data-aos="fade-right" >
      </div>
      <div class="block" id="block5">
        <div data-aos="fade-right" >
        <h2 class="block-title">생물다양성및 생태계 시민과학</h2>
        <span class="block-count">05</span>
        <span class="block-subtitle">독수리학교</span>  
        <p class="block-content">
          지역울산의 생물다양성 보존과 생태계 복원을 위한 다양한 교육과
          보전활동을 진행합니다. 태화강겨울철새학교, 태화강백로생태학교,
          시민과학생태탐사, 독수리생태학교등 생물다양성보존및 복원을 
          위한 다양한 노력을 진행하고 있습니다.
       </p>
       <router-link to="/aboutus" class="block-more">read more</router-link>
      </div>
        <img src="../assets/etc/종다양성보존운동_566x720.jpg" data-aos="fade-left"  alt="">
      </div>
    </section>
   </main>
  </div>
</template>

<script>
import band from '../assets/Icons/band.svg'
import youtube from '../assets/Icons/youtube-brands.svg'
import arrow from '../assets/Icons/arrow-right-light.svg'

export default {
  
  name: 'AboutUs',
  components: { band, youtube, arrow },
 
  data () {
    return {
      isStart: true,
      is01: false,
      is02: false,
      is03: false,
      is04: false,
      is05: false,
    }
  },
  methods: {
    chk00 () {
      this.isStart = true,
      this.is01 = false,
      this.is02 = false,
      this.is03 = false,
      this.is04 = false,
      this.is05 = false          
    },
    chk01 () {
      this.isStart = false,
      this.is01 = true,
      this.is02 = false,
      this.is03 = false,
      this.is04 = false,
      this.is05 = false          
    },
    chk02 () {
      this.isStart = false,
      this.is01 = false,
      this.is02 = true,
      this.is03 = false,
      this.is04 = false,
      this.is05 = false
    },
    chk03 () {
      this.isStart = false,
      this.is01 = false,
      this.is02 = false,
      this.is03 = true,
      this.is04 = false,
      this.is05 = false
    },
    chk04 () {
      this.isStart = false,
      this.is01 = false,
      this.is02 = false,
      this.is03 = false,
      this.is04 = true,
      this.is05 = false
    },
    chk05 () {
      this.isStart = false,
      this.is01 = false,
      this.is02 = false,
      this.is03 = false,
      this.is04 = false,
      this.is05 = true
    },
    downBtn() {      
      window.scrollTo(100, document.body.scrollHeight || document.documentElement.scrollHeight)      
    }
  },  
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Hahmlet:wght@100..900&family=Lobster&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import '../scss/index';


.about {
  background-color: $color-dark-green;  
  overflow: auto;
 
  // .dimmed {
  //   position: absolute;
  //   width: 100%;
  //   height: 100px;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;       
  //   background: linear-gradient(330.24deg, rgba(11,29,38,0) 31.06%, #0b1d26 108.93%);
  // }
  .visual {    
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // z-index: 20;
     height: 1200px;
    

    @media screen and ( max-width: 1024px) {
     width: 100%;
    }
      
    @media screen and ( max-width:767px ) {       
        min-height: 650px;
        max-height: 650px;
        flex-direction: row;       
        }
    
    .quick {
      position: fixed;
      right: 8rem;
      top: 50%;
      z-index: 20;
      transform: translateY(-50%);
      @media screen and ( max-width: 550px) {
        display: none;
      }
      a {
        display: block;
        padding-right: 3.2rem;
        text-align: right;
        font-size: 1.8rem;
        line-height: 6rem;        
        color: $color-fff-05;
        border-right: 0.3rem solid $color-fff-05;
        &:hover {
          color: $color-1eb8b8;
          font-weight: 700;
        }
        &.active {
          border-color: $color-fff;
        }
      }      
    }
    .sns {
      display: flex;
      z-index: 20;
      flex-direction: column;
      gap: 2.4rem;
      position: fixed;
      left: 8rem;
      top: 50%;
      transform: translateY(-50%);
      @media screen and ( max-width: 550px) {
        display: none;
      }
      .sns-title {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        font-size: 2rem;
        font-weight: 700;
        color: $color-fff;            
        @media screen and ( max-width: 1024px) {
          top: 32rem;
        }
        @media screen and ( max-width:767px ) {
          top: 25rem;
        }
      
      }
      .btn-band {       
        width: 3rem;
        height: 3rem;            
      }
      .btn-youtube {       
        width: 3.5rem;
        height: 3.5rem;        
        color: red;
      }
    }
  }
  .bg-img1 {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
   animation: bg 1 2s both;
  }
  .bg-img2 {
    position: absolute;
    left: 0;
    top: 46rem;
    max-width: 100%;   
    animation: bg 1 3s 0.5s both; 
    @media screen and ( max-width: 1024px) {
      top: 32rem;
    }
    @media screen and ( max-width:767px ) {
          top: 25rem;
        }
    @media screen and ( max-width: 600px ) {
          top: 21rem;
        }   
    @media screen and ( max-width: 400px ) {
          top: 15rem;
        }      
    }
  }
  .bg-img3 {
    position: absolute;
    left: 0;
    top: 76rem;
    max-width: 100%;    
    animation: bg 1 1s 0.1s both;
    @media screen and (max-width:1024px){
      top: 60rem;
    }
    @media screen and ( max-width:767px ) {
          top: 50rem;
    }
    @media screen and ( max-width: 600px ) {
          top: 38rem;
        }   
    @media screen and ( max-width: 400px ) {
          top: 28rem;
        }   
  }
  @keyframes box {
    0% { transform: scale(3);}
    100% {transform: scale(1);}
  }
  @keyframes bg {
    0% { transform: translateY(-50px); opacity: 0;}
    50% { opacity: 0.5;}
    100% { transform: rotateY(0); opacity: 1;}
  }
  @keyframes txt {
    0% { transform: translateX(-50px); opacity: 0;}    
    100% { transform: rotateX(0); opacity: 1;}
  }
  .gradient-content {
    height: 700px;
    background-image: linear-gradient(180deg, rgba($color-light-green,0) 0,$color-dark-green 61.38%);
    opacity: 0.6;
    @media screen and ( max-width:1024px ) {
      height: 100px;      
    }
    @media screen and ( max-width:768px ) {
      height: 0;
    }    
    
  }
  .index-contents {
    display: grid;
    gap: 20rem;
    margin: 0.5rem auto;
   
    @media screen and ( max-width:1024px ) {
      gap: 10rem;      
      margin: -20rem auto 0;
    }
    @media screen and ( max-width:768px ) {
      margin-top: -50rem 0 1rem 0;
    }
    @media screen and (max-width: 600px) {                
      padding-left: 0;
    }       
    @media screen and (max-width: 500px) {                
      margin: -50rem auto;
      padding-left: -10rem;
    }   
    
  //  #bolck4 {
  //   .block-count {
  //     margin-left: 100rem;
  //   }
  //  }
  }
  .visual-content {
      display: grid;
      gap: 3.2rem;
      position: absolute;
      left: 50%;
      top: 18rem;
      z-index: 20;
      width: 100%;
      transform: translateX(-50%);   

      .subtitle {
        display: flex;
        align-items: center;
        gap: 3.2rem;
        font-size: 1.8rem;
        line-height: 2.2rem;
        letter-spacing: 0.6rem;
        color: $color-gold;
        text-shadow: 0 0 10px $color-light-green;
        font-family: "Philosopher", sans-serif;
        font-weight: 700;
        font-style: normal;
        animation: txt 1 1s 1s both;
        @media screen and (max-width:1024px) {
          
        }
        @media screen and (max-width:768px) {
          font-size: 1.5rem;   
          margin-top: -1rem;                 
        }
        &::before {         
          content: '';
          width: 7rem;
          height: 0.2rem;
          background: $color-gold;
          @media screen and (max-width:768px) {
          width: 4rem;                    
        }
        }
      }
      .maintitle {
        text-align: center;
        font-size: 3.5rem;
        line-height: 6rem;
        font-weight: 700;
        letter-spacing: 0.6rem;
        color: $color-fff;
        font-family: "Philosopher", sans-serif;        
        font-style: normal;
        animation: txt 1 1s 1.5s both;
        @media screen and (max-width:1024px) {
          
        }
        @media screen and (max-width:768px) {
          font-size: 2.2rem;
          font-weight: 600;
          letter-spacing: 0.1rem;
          text-align: center;
          margin-top: -2rem;
          line-height: 4rem;
        }
        @media screen and (max-width:600px) {
          text-align: center;
          font-size: 2rem;
          font-weight: 500;
          letter-spacing: 0.1rem;
          text-align: center;
          margin-top: -2rem;
          line-height: 4rem;
        }
        @media screen and (max-width:500px) {
          text-align: center;
          font-size: 2rem;
          font-weight: 500;
          letter-spacing: 0.1rem;
          margin-left: 1.5rem;
          margin-top: -2rem;
          line-height: 3rem;
        }
      }
      .text-guide {
        display: flex;
        align-items: center;
        margin-left: 20rem;
        font-size: 1.8rem;
        line-height: 2.2rem;        
        font-weight: 700;       
        color: $color-fff;
        animation: txt 1 1s 1.8s both;
        cursor: pointer;
        scroll-behavior: smooth;
        @media screen and (max-width:1024px) {
          font-size: 1.5rem;
        }
        @media screen and (max-width:768px) {
          font-size: 1.5rem;
        }
        .arrow {
          transform: rotate(90deg);
          margin-left: 1.6rem;
          width: 2.4rem;
          height: 2rem;
          @media screen and (max-width:1024px) {
          width: 1.5rem;
          height: 1.6rem;
        }
          @media screen and (max-width:768px) {
          width: 1.5rem;
          height: 1.6rem;
        }
        >path {
         fill: $color-fff;         
        }
        }
      }
    }
    .block {
      display: flex;
      padding-left: 12rem;               
      gap: 12rem;
      position: relative;    
      width: 100%;
      // height: 50vh;
      @media screen and (max-width:1024px){
        width: 85%;
        padding-top: 0;
      }     
      @media screen and ( max-width:768px ) {
         max-width: 100%;
        }
      @media screen and ( max-width:500px ) {
         max-width: 80%;
         margin-left: -1rem;
        }
      
      img {
        // max-width: 100%;
        margin-left: 1rem;
        padding: 0 10rem 0 0;
        @media screen and (max-width:1024px){
        // width: 80%;
        // max-height: 80%;
      }     
      @media screen and ( max-width:768px ) {
          display: flex;
          // flex-direction: column;
         justify-content: center;
         align-items: center;
        }
      }
     
      @media screen and (max-width:1024px){
        top: 60rem;
      }     
      @media screen and ( max-width:768px ) {
          display: flex;
          flex-direction: column;
          padding-left: 12rem;
        }
     
      &:nth-of-type(even) {
        flex-direction: row-reverse;
        @media screen and ( max-width:768px ) {
          flex-direction: column;
        }
        // .block-count {          
        //   // left: -18rem;
        //   // top: -5rem;
        //   @media screen and (max-width:1024px){
       
        //   }     
        //   @media screen and ( max-width:768px ) {  
        //     }
        // }
        // .block-content {
        //   margin-left: -15rem;
        //   @media screen and (max-width:1024px){
        //   font-size: 1rem;
        //   width: 200px;
        //   }     
        //   @media screen and ( max-width:768px ) {
              
        //     }
        // }
        // .block-subtitle {
        //   margin-left: -15rem
        // }
        // .block-title {
        //   margin-left: -15rem
        // }
        
      }      
    
       > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2.7rem;
        margin-right: 15rem;   
        margin-bottom: -10rem;     
        @media screen and (max-width:1024px){
          top: 60rem;
        } 
        @media screen and ( max-width:768px ) {
          display: flex;
          flex-direction: column;
          padding-left: 12rem;
        }
        
        .block-subtitle {
          order: 1;
          display: flex;
          align-items: center;
          gap: 3.2rem;
          font-size: 1.8rem;
          line-height: 2.2rem;
          letter-spacing: 0.6rem;
          color: $color-gold;         
          @media screen and (max-width:1024px){
            top: 60rem;
          } 
          @media screen and ( max-width:768px ) {
          top:-20rem;
          width: 700px;
        }
          @media screen and ( max-width:500px ) {
          margin-left: -6rem;
          width: 700px;
          letter-spacing: 0;
          gap: 0;
        }

          &::before {
            display: inline-block;
            content: '';
            width: 7rem;
            height: 0.2rem;
            background: $color-gold;
          }
        }
        .block-title {
          order: 2;
          font-size: 2.2rem;
          line-height: 8rem;
          color: $color-fff;
          @media screen and ( max-width: 1024px) {
            font-size: 2.5rem;
          }
          @media screen and ( max-width:768px ) {
            font-size: 2rem;
            margin-left: -10rem;
        }
          @media screen and ( max-width:600px ) {
            font-size: 1.8rem;
            margin-left: -18rem;
        }
          @media screen and ( max-width:500px ) {
            font-size: 1.8rem;
            margin-left: -20rem;
        }
        }
        .block-content {
          order: 3;
          font-size: 1.9rem;
          line-height: 3.5rem;
          color: $color-fff;
          width: 100%;         
          @media screen and ( max-width: 1024px) {            
            font-size: 1.8rem;
            width: 200px;
          }
          @media screen and ( max-width:768px ) {            
            font-size: 1.5rem;
            width: 70%;
            text-align: left;
            margin-left: -10rem;
        }
          
          @media screen and ( max-width:500px ) {            
            font-size: 1.5rem;
            width: 60%;
            text-align: left;
            margin-left: -20rem;
        }
        }        
        .block-more {
          order: 4;
          font-size: 1.8rem;
          line-height: 2.2rem;
          color: $color-gold;
        }
        .block-count {
          font-family: "Black Han Sans", sans-serif;
          font-weight: 400;
          font-style: normal;
          position: absolute;
          left: 0;
          top: -7rem;
          font-size: 22rem;
          line-height: 1;
          color: $color-fff-01;
          letter-spacing: -0.8rem;
          @media screen and ( max-width: 1024px) {
            top: 32rem;
          }
          @media screen and ( max-width:768px ) {
          font-size: 18rem;
          top: -8rem;
        }
        }      
      }      
      img {
       max-width: 55.6rem;    
      //  height: 100%;  
       @media screen and (max-width:1024px){
         top: 50rem;
       }
       @media screen and ( max-width:767px ) {
          // width: 50%;
        }
       @media screen and ( max-width:500px ) {
          width: 60%;
          margin-left: -8.5rem;
        }
      }      
    }
</style>