<template>
  <div class="post-view" v-if="currentBlog">
    <div class="container quillWrapper">
      <h2>{{ this.currentBlog[0].blogTitle }}</h2>
      <h4>게시일: {{ new Date(this.currentBlog[0].blogDate).toLocaleString('ko-KR', {dateStyle: 'long' })}}</h4>
      <img :src="this.currentBlog[0].blogCoverPhoto" alt="Cover Photo" />
      <div class="post-content ql-editor" v-html="this.currentBlog[0].blogHTML"></div>
    </div>
    <button>
      <router-link class="link" :to="{ name: 'Blogs'}">뒤로가기</router-link>
    </button>
  </div>
</template>

<script>
export default {
    name: 'ViewBlog',
    data() {
        return {
        currentBlog: null
        }
    },
    async mounted() {
        this.currentBlog = await this.$store.state.blogPosts.filter((post) => {
            return post.blogID === this.$route.params.blogid
    })
    },
}
</script>

<style lang="scss">
.post-view {
    margin-top: 3.5em;    
    button,
    .router-button {
      transition: 0.5s ease-in-out all;
      align-self: center;
      font-size: 1.4rem;
      cursor: pointer;
      border-radius: 2rem;
      padding: 1.2rem 2.4rem;
      color: #fff;
      background-color: #c3c0c0;
      text-decoration: none;
      &:hover {
          background-color: rgba(48,48,48,0.7);
          color: #fff;
          font-weight: 700;
        }
    }
    .container {
        // text-align: center;
        // position: relative;
        // .post-content {
        //     font-weight: 500;
        //     font-size: 1.5rem;
        //     margin-bottom: 2.4rem;
        //     }  
        
        h2 {
            text-align: left;
        }
        p {
            text-align: left;
        }
         h4 {
            font-weight: 400;
            font-size: 1.5rem;
            margin-bottom: 2.4rem;
            text-align: left;
            // position:absolute;
            // top:0;
            // left: -30%;
            // width: 100%;
            // height: 100%;           
        }
        // img {
        //     width: 100%;
        // }
       
    }  
}
</style>

