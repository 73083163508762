<template>
  <div class="app-wrapper">
    <div class="app" v-if="this.$store.state.postLoaded">
      <NavigationView v-if="!navigation" class="header" />
      <router-view />      
      <FooterView v-if="!navigation"></FooterView>    
      <div @click="topBtn" class="moveTop">Top</div>
    </div>    
  </div>
</template>

<script>
import NavigationView from '@/components/NavigationView'
import FooterView from '@/components/FooterView'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'app',
  components: { NavigationView, FooterView },
  data() {
    return {
      navigation: null
    }
  },  
  created() {
    firebase.auth().onAuthStateChanged(async(user) => {
      this.$store.commit('updateUser', user)
      if (user) {
        // const token = await user.getIdTokenResult();
        // console.log(token.claims);
        this.$store.dispatch('getCurrentUser', user)
        // console.log(this.$store.state.profileEmail)
      }
    })
    this.checkRoute()
    this.$store.dispatch('getPost')
    // console.log(firebase.auth().currentUser)   
   
  },
  methods: {
    checkRoute() {
      if (this.$route.name === 'Login' || 
          this.$route.name === 'Register' || 
          this.$route.name === 'ForgotPassword'
        ) {
          this.navigation = true
          return
      } 
      this.navigation = false
    },    
    topBtn() {      
      window.scrollTo({ top: 0, behavior: 'smooth' })      
    }  
  },
  watch: {
    $route() {
      this.checkRoute()
    }
  }  
};

</script>

<style lang="scss">
@import './scss/index';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .moveTop {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 4rem;
    height: 4rem;
    background: #9e9c9c;
    color: #fff;
    border-radius: 5rem;
    text-align: center;
    line-height: 1.6;
    font-size: 2rem;
    cursor: pointer;
    z-index: 100%;
    background: radial-gradient(75.61% 75.61% at 50% 24.39%, rgba(180, 83, 9, 0.9)0%, rgba(180, 83, 9, 0.2) 100%);
  }
}
// .header {
//   // position: fixed;
//   top: 0.5rem;
//   left: 0rem;
//   right: 0; 
// }

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
 
}

.link-light {
  color: #fff;
}
.arrow {
  margin-left: 0.8rem;
  width: 1.2rem;
  path {
    fill: #000;
  }
}
.arrow-light {
  path {
    fill: #fff;
  }
}

button,
.router-button {
  transition: 500ms ease all;
  cursor: pointer;
  margin-top: 2.4rem;
  padding: 1.2rem 2.4rem;
  background-color: #303030;
  color: #fff;
  border-radius: 2rem;
  border: none;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgba(48, 48, 48,0.7);
  }
}
.button-ghost {
  color: #000;
  padding: 0;
  border-radius: 0;
  margin-top: 5rem;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: transparent;
  @media (min-width: 700px) {
    margin-top: 0;
    margin-left: auto;
  }
  i {
    margin-left: 0.8rem;
  }
}
.button-light {
  background-color: transparent;
  border: 0.2rem solid #fff;
  color: #fff;
}
.button-inactive {
  pointer-events: none !important;
  cursor: none !important;
  background-color: rgba(128,128,128,0.5) !important;
}

.error {
  text-align: center;
  font-size: 1.2rem;
  color: red;
}
.blog-card-wrap {
  position: relative;
  padding: 8rem 1.6rem;
  background-color: $color-f1f1f1;
  @media (min-width: 500px) {
    padding: 5rem 1.6rem;
  }

  .blog-cards {
    display: grid;
    gap: 3.2rem;
    grid-template-columns: 1fr;
    @media(min-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }   
    @media(min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>
