<template>
   <div class="form-wrap">
        <form class="register">
            <p class="login-register">
                이미 계정이 있습니까?
                <router-link class="router-link forgot-password" style="margin-left: 1rem;" :to="{ name: 'Login'}">로그인</router-link>
            </p>
            <h3>계정을 만들겠습니까?</h3>
            <div class="inputs">              
                <div class="input">
                    <input type="text" placeholder="사용자 이름" v-model="username" />
                    <user class="icon" />
                </div>            
                <div class="input">
                    <input type="email" placeholder="이메일" v-model="email" />
                    <email class="icon" />
                </div>               
                <div class="input">
                    <input type="password" placeholder="비밀번호" v-model="password" />
                    <password class="icon" />
                </div>
                <div class="input">                    
                    <input type="password" placeholder="비밀번호 확인" v-model="rePassword" />
                    <password class="icon icon-green" />
                </div>
               <div v-show="error" class="error">{{ this.errorMsg }}</div>
            </div>            
            <button @click.prevent="register">등록</button>
            <LoadingView v-if="loading" />
            <div class="angle"></div>
        </form>
        <div class="background"></div>
    </div>
</template>

<script>
import email from '../assets/Icons/envelope-regular.svg'
import password from '../assets/Icons/lock-alt-solid.svg'
import user from '../assets/Icons/user-alt-light.svg'
import LoadingView from '../components/LoadingView.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import db, { timestamp } from '../firebase/firebaseInit'

export default {
    name: 'Register',
    components: { email, password, user, LoadingView },
    data: () => ({
        username: '',
        email: '',
        password: '',
        rePassword: '',
        error: null,
        errorMsg: '',        
        loading: false
    }),
    methods: {
        async register () {                        
            if (
                this.username !== '' && 
                this.email !== '' && 
                this.password !== '' &&
                this.password === this.rePassword
                ) {
                    this.loading = true
                    this.error = false
                    this.errorMsg =''
                    const firebaseAuth = await firebase.auth()
                    const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password)
                    const result = await createUser
                    const dataBase = db.collection('users').doc(result.user.uid)
                    await dataBase.set ({
                        email: this.email,
                        username: this.username,                          
                        createdAt: timestamp(new Date())                 
                    })                    
                    this.loading = false
                    this.$router.push({ name: 'Home'})
                    return
            }
            this.loading = false
            this.error = true
            console.log(this.error)
            this.errorMsg = '이메일과 비밀번호를 확인해주세요'
            return
        },
    },

}
</script>

<style lang="scss" scoped>
.register {
    h3 {
        max-width: 350px;
        padding-bottom: 1rem;
    }    
    .icon-green{
        path {
            fill: green;
        }
}
}
</style>