<template>
  <footer>
    <div class="container">
        <div class="left">
            <div class="col-1">
                <router-link class="header" :to="{ name:'Home' }">
                    <div class="fonts">
                        <!-- <p>
                            <img src="../assets/logo.png" style="width: 20%;" alt="">
                        </p> -->
                        <span class="lobster-regular">With</span>
                        <span class="bagel-fat-one-regular">울산독수리</span>
                    </div>
                </router-link>
        <ul>
            <li><a href="#"><youTube class="svg-icon"/></a></li>
            <li><a href="#"><twitter class="svg-icon"/></a></li>
            <li><a href="#"><instagram class="svg-icon"/></a></li>
            <li><a href="#"><linkedin class="svg-icon"/></a></li>
        </ul>
        </div>
        <div class="col-2">
          <ul>
            <router-link class="link" :to="{name: 'Home'}">Home</router-link>
            <router-link class="link" :to="{name: 'Blogs'}">Blogs</router-link>
            <router-link class="link" :to="{ name: 'AboutUs'}">About Us</router-link>
            <router-link v-if="admin" class="link" :to="{ name: 'CreatePost'}">Create Post</router-link>
            <router-link class="link" :to="{ name: 'DonationUs'}">후원하기</router-link>
            <router-link v-if="!user" class="link" :to="{name: 'Login'}">Login/Register</router-link>          
          </ul>
          </div>
        </div>
        <div class="right">
            <p>Copyright<strong>&copy; {{ new Date().getFullYear() }} All Rights Reserved</strong></p>
        </div>
        
    </div>
  </footer>
</template>

<script>
import youTube from  '../assets/Icons/youtube-brands.svg'
import twitter from  '../assets/Icons/twitter-brands.svg'
import instagram from  '../assets/Icons/instagram-brands.svg'
import linkedin from  '../assets/Icons/linkedin-brands.svg'

export default {
  name: 'FooterView',
  components: {
    youTube,
    twitter,
    instagram,
    linkedin
  },
  data (){
    return {
      isYear: null
  }
  },
    computed: {
      user () {
        return this.$store.state.user
      },
      admin () {
        return this.$store.state.profileAdmin
      },      
  }    

}
</script>

<style lang="scss" scoped>
@import '../scss/mixin';
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Lobster&display=swap');


@media (min-width: 1280px) {
    @import '../scss/_foot1200';
}
footer {
    margin-top: auto;
    padding: 10rem 2.5rem;
    background-color: teal;  

    .container {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        
        @media (min-width: 800px) {
            flex-direction: row;
            gap: 0;
        }    
        > div {
            display: flex;
            flex: 1;
        }
        .left {
            gap: 3.2rem;
            color: #fff;
            display: flex;
            flex-direction: column;
            // margin-left: -10rem;
            align-items: center;
            @media (min-width: 800px) {
                flex-direction: row;
                align-items: initial;
                gap: 0;                                                            
            }    
            // @include desktop {
            //     margin-left: 10rem;
            // }                                    
            .header {        
                text-align: center;
                box-sizing: border-box;
                font-size: 2.4rem;
                //   width: 23rem;
                color: #fff;
                text-decoration: none;
                font-weight: 600;
                  margin-left: -7rem;
                  @media (min-width: 800px) {
                      text-align: initial;
                      margin-left: 0;
                  }       
            .fonts {
               width: 16rem;
               
             
              .lobster-regular {
                font-family: "Lobster", sans-serif;
                font-weight: 200;
                font-style: normal;
                color: black;                
                
                }
                .bagel-fat-one-regular {
                    font-family: "Bagel Fat One", system-ui;
                    font-weight: 200;
                    font-style: normal;
                    margin-left: 0.8rem;                    
                }
            }
            }
            ul {
                gap: 1.5rem;
                list-style: none;
                display: flex;
                justify-content: center;
               
                @media (min-width: 800px) {                      
                      margin-left: 8rem;
                  }       
                @media (max-width:500px) {                      
                      margin-left: 1rem;
                  }                         
            }

            .col-1,
            .col-2 {
                gap: 3.2rem;
                display: flex;
                flex: 1;
                // margin-left: 7rem;              
                @media (min-width: 800px) {
                    gap: 0;
                }
            }

            .col-1 {
                flex-direction: column;
                                               
                h2 {
                text-align: center;
                @media (min-width: 800px) {
                text-align: initial;
                }
                }
                ul {
                    margin-top: auto;

                    li {
                        display: flex;
                        align-items: center;
                        .svg-icon {
                            width: 2.4rem;
                            height: auto;
                            color: #fff;
                        }
                    }
                }
            }

            .col-2 {
                ul {               
                  height: 100%;
                  display: grid;
                  margin: 0 auto;
                  text-align: center;
                  justify-content: center;
                  flex-direction: row;
                  flex-wrap: wrap;
                  margin-left: -2.5rem;
                  min-width: 15rem;
                  @media (max-width: 500px) {
                      flex-direction: column;
                      margin-left: -5rem;
                  }
                  @media (min-width: 800px) {
                      flex-direction: column;
                      margin-left: 15rem;
                  }
                  @media (min-width: 1200px) {                       
                      margin-left: 25rem;
                  }
                  // @include desktop {
                  //      margin-left: 20rem;
                  //  }
                
                  .link {
                      font-size: 1.2em;
                      font-weight: 500;
                      color: #fff;
                      text-decoration: none;
                      &:hover {
                          color: black;
                }
                  }
              }
            }
        }
        .right {
            gap: 3.2rem;
            color: #fff;
            align-items: center;
            // position: relative;
            flex-direction: column;   
                     
            @media (min-width: 800px) {
                    // align-items: flex-end;
                    gap: 0;
                    position: absolute;
                    margin:9rem 0 0 48rem;                  
                    
                    // font-size: 1rem;                                        
                }
            @media (min-width: 1000px) {
                    // align-items: flex-end;
                    gap: 0;
                    position: absolute;
                    margin: 9rem 0 0 60rem;                  
                    
                    // font-size: 1rem;                                        
                }
            @media (min-width: 1200px) {
                    // align-items: flex-end;
                    gap: 0;
                    // position: absolute;
                    
                    margin: 9rem 0 0 90rem;                                  
                }
            @include desktop {
                
            }    
        }
        p {
          margin-top: auto;  
          color: $color-fff-05;              

          // @include desktop {
          //     position: absolute;
          //     top: 12rem;
          //     right: -80rem;                
          // }
        }

    }
}
</style>