<template>
<header>
    <nav class="container">
        <div class="branding">
            <div class="logo-img">
                <img src="../assets/logo.png" style="width: 10%;"  alt="독수리로고">
                <router-link class="header" :to="{ name: 'Home' }">
                    <span class="lobster-regular">With</span><span class="bagel-fat-one-regular">울산독수리</span>
                </router-link>
            </div>
        </div>
        <div class="nav-links">
            <ul v-show="!mobile">
                <router-link class="link" :to="{ name: 'Home' }">Home</router-link>
                <router-link class="link" :to="{ name: 'Blogs'}">Blogs</router-link>
                <router-link class="link" :to="{ name: 'AboutUs'}">About Us</router-link>
                <router-link class="link" :to="{ name: 'DonationUs'}">후원하기</router-link>
                <router-link v-if="admin" class="link" :to="{ name: 'CreatePost'}">Create Post</router-link>
                <router-link v-if="!user" class="link" :to="{ name: 'Login' }">
                    <account class="account-icon" />Login/Register
                </router-link>
            </ul>
            <div v-if="user" @click="toggleProfileMenu" class="profile" ref="profile">
                <span>{{ this.$store.state.profileInitials }}</span>
                <div v-show="profileMenu" class="profile-menu" @click="closeMenu" id="idCloseMenu">
                    <div class="info">
                        <p class="initials">{{ this.$store.state.profileInitials }}</p>
                        <div class="right">                            
                            <p>{{ $store.state.profileUsername }}</p>
                            <p>{{ $store.state.profileEmail }}</p>                            
                        </div>
                    </div>
                    <div class="options">
                        <div class="option">
                            <router-link class="option" :to="{ name: 'Profile' }">
                                <userIcon class="icon" />
                                <p>회원정보수정</p>
                            </router-link>
                        </div>
                         <div v-if="admin" class="option">
                            <router-link class="option" :to="{ name: 'Admin' }">
                                <adminIcon class="icon" />
                                <p>관리자</p>
                            </router-link>
                        </div>
                         <div @click="signOut" class="option">
                            <signOutIcon class="icon" />
                              <p>로그 아웃</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <menuIcon @click="toggleMobileNav" class="menu-icon" v-show="mobile"/>
    <transition name="mobile-nav">
      <ul class="mobile-nav" v-show="mobileNav" @click="closeSideMenu"> 
        <router-link class="link" :to="{ name: 'Home' }">Home</router-link>
        <router-link class="link" :to="{ name: 'Blogs' }">Blogs</router-link>
        <router-link class="link" :to="{ name: 'AboutUs'}">About Us</router-link>
        <router-link class="link" :to="{ name: 'DonationUs'}">후원하기</router-link>
        <router-link v-if="admin" class="link" :to="{ name:'CreatePost' }">
            Create Post
        </router-link>
        <router-link v-if="!user" class="link icon-account" :to="{ name: 'Login' }">
          Login/Register
        </router-link>
      </ul>
    </transition>  
   
</header>
</template>

<script>
import menuIcon from '@/assets/Icons/bars-regular.svg'
import userIcon from '@/assets/Icons/user-alt-light.svg'
import adminIcon from '@/assets/Icons/user-crown-light.svg'
import signOutIcon from '@/assets/Icons/sign-out-alt-regular.svg'
import account from '@/assets/Icons/user-alt-light.svg'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
    name: 'NavigationView',
    components: { menuIcon, userIcon, adminIcon, account, signOutIcon },
    data () {
      return {
        profileMenu: null,
        mobile: null,
        mobileNav: null,
        windownWidth: null
      }
    },
    created() {
        window.addEventListener('resize', this.checkScreen)
        this.checkScreen()
    },
    methods: {
        checkScreen() {
            this.windownWidth = window.innerWidth
            if (this.windownWidth <=750) {
                this.mobile = true
                return
            }
            this.mobile = false
            this.mobileNav = false
            return
      },
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav
        },
        toggleProfileMenu (e) {
            if (e.target === this.$refs.profile) {
                this.profileMenu = !this.profileMenu
            }
        },
        signOut() {
            firebase.auth().signOut()
            window.location.reload()
            this.$router.push({ name: 'Home'})
        },
        closeMenu() {
          document.getElementById('idCloseMenu').style.display='none'
        },
        closeSideMenu()  {
          this.mobileNav = !this.mobileNav
        }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      admin () {
        return this.$store.state.profileAdmin
      }
    }
}

</script>

<style lang="scss" scoped>
@import '../scss/index';
    header {
        background-color: $color-fff;
        padding: 0 2.5rem;
        box-shadow: $box-shadow;
        z-index: 99;
        position: fixed;
        left: 0;
        right: 0;
          
        // margin-top: -0.5rem;        
       
    .link {
        font-weight: 500;
        padding: 0 0.8rem;
        transition: .0.3s color ease;      

        &:hover {
            color: $color-1eb8b8;
        }
    }
    nav {
        display: flex;
        padding: 2.5rem 0;
        
        .branding {
            display: flex;
            align-items: center;          
        //    img {
        //        padding: 0 10;               
        //        @media (min-width:150rem) {
        //        margin-left:-25%;
        //        } 
            }

            .header {
                font-weight: 600;
                font-size: 2.4rem;
                color: #000;
                text-decoration: none;                
                // margin-top: 1.5rem;
                // margin-left: 2rem;
                // line-height: 3rem;  
                // height: 3rem;        
                // @media (max-width: 80rem) {
                //  margin-top: 0px;;
                // }
               
                .lobster-regular {
                font-family: "Lobster", sans-serif;
                font-weight: 400;
                font-style: normal;
                margin-left: 2rem;                 
                }
                .bagel-fat-one-regular {
                    font-family: "Bagel Fat One", system-ui;
                    font-weight: 400;
                    font-style: normal;
                    margin-left: 0.8rem;
                    }
            }           
        }
        .nav-links {
            position: relative;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;
           
            ul {
                margin-right: 3.2rem;
                display: flex;   
                align-items: center;               

                .account-icon {                               
                    width: 1.5rem;
                    height:1.5rem;
                    margin-right: 0.8rem;                                      
            }

                .link {
                    margin-right: 3.2rem;
               
                    
                }
                .link:last-child {
                    margin-right: 0;
                }
               
            }
            .profile {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                color: #fff;
                background-color: rgb(89, 86, 86);
                &:hover {
                    color: #000;
                    background-color: #b6b5b3;
                    font-weight: bold;
                }
                @media (max-width:751px) {
                    // display: none;
                    margin-right: 50px;
                }
                span {
                    pointer-events: none;
                }
                .profile-menu {
                    position: absolute;
                    top: 6rem;
                    right: 0;
                    width: 25rem;
                    background-color:rgb(74, 72, 72);
                    box-shadow: $box-shadow;
                    :hover {
                        color: #d4c309;
                        background-color: $color-354147;
                    }

                    .info {
                        display: flex;
                        align-items: center;
                        padding: 1.5rem;
                        border-bottom: 1px solid $color-fff;

                         .initials {
                            position: initial;
                            width: 4rem;
                            height: 4rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            color: $color-303030;
                            background: radial-gradient(75.61% 75.61% at 50% 24.39%, rgba(210, 212, 211, 0.9)0%, rgba(231, 212, 4, 0.2) 100%);
                            // background-color: $color-fff; 
                         }
                         .right {
                            flex: 1;
                            margin-left: 2.4rem;
                            p:nth-child(1) {
                                font-size: 1.4rem;
                            }                            
                            P:nth-child(2),
                            p:nth-child(3) {
                                font-size: 1.2rem;
                                margin-top: 0.8rem;
                           }
                     }
                }
                .options {
                    padding: 1.5rem;
                    .option {
                        text-decoration: none;
                        color: $color-fff;
                        display: flex;
                        align-items: center;
                        margin-bottom: 1rem;
                        .icon {
                            width: 1.8rem;
                            height: auto;
                        }
                        p {
                            font-size: 1.4rem;
                            margin-left: 1.2rem;
                        }
                        &:nth-last-child {
                            margin-bottom: 0;
                        }
                    }                   
                    }
                }
            }
        }    
    }
    .menu-icon {
        cursor: pointer;
        position: absolute;
        top: 3.2rem;
        right: 2.5rem;
        height: 2.5rem;
        width: auto;
        // @media screen and (max-width: 80rem) {
        //  top: 1.5rem;
        // }
    }
    .mobile-nav {
        padding: 2rem;
        width: 70%;
        max-width: 25rem;
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100%;
        background-color: $color-303030;
        top: 0;
        left: 0;
        .link {
            padding: 1.5rem 0;
            color: $color-fff;
        }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
        transition: all 1s ease;
    }

    .mobile-nav-enter {
        transform: translateX(-25rem);
    }

    .mobile-nav-enter-to {
        transform: translateX(0);
    }
    .mobile-nav-leave-to {
        transform: translateX(-25rem);
    }
 
</style>