<template>
  <div class="home">
    <BlogPost v-if="!user" :post="welcomeScreen" />
    <BlogPost :post="post" v-for="(post, index) in sampleBlogPost" :key="index" />
    <div class="blog-card-wrap">
      <div class="container">
        <h3>최근 블로그 소식</h3>
        <div class="blog-cards">
          <BlogCard :post="post" v-for="(post, index) in blogPostsCards" :key="index" />
        </div>
      </div>
    </div>
    <div v-if="!user" class="updates">
      <div class="container">
        <div class="container-text">
          <h2 class="jua-font">울산독수리와 함께</h2>
          <h5>다음 세대를 위한 녹색운동,</h5>
          <h5>시민여러분과 함께 이루어 갑니다.</h5>
        </div>
        <router-link class="router-button" :to="{ name: 'Register'}">
          회원등록<Arrow class="arrow arrow-light" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BlogPost from '../components/BlogPost'
import BlogCard from '../components/BlogCard'
import Arrow from '../assets/Icons/arrow-right-light.svg'

export default {
  name: "Home",
  components: { BlogPost, BlogCard, Arrow },

  data () {
   return  {   
  }
  },
  computed: {
    welcomeScreen () {
      return this.$store.getters.welcomeScreen
    },
    sampleBlogPost () {
      return this.$store.getters.sampleBlogPost
    },
    blogPostsFeed() {
      return this.$store.getters.blogPostsFeed;
    },
    blogPostsCards() {
      return this.$store.getters.blogPostsCards;
    },
    user() {
      return this.$store.state.user
    }
    
  },
  
};
</script>
<style lang="scss" scoped>

@import '../scss/mixin';
.blog-card-wrap {        
  h3 {
    font-weight: 300;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;      
  }
}
.updates {  
  .container {
    padding: 5rem 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 800px) {
      padding: 5rem 2.5rem;
      flex-direction: row;
    }
    @include desktop {
      padding: 3rem 2rem;
    }
    .container-text {
      line-height: 2;
      text-wrap: balance;
      text-align: center;
      h2 {
        text-shadow: 2px 2px gray;
      }
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      
      
    }
    .router-button {
      display: flex;
      font-size: 1.4rem;
      text-decoration: none;
      @media (min-width: 800px) {
        margin-left: auto;
      }
    }
    h2 {
      font-weight: 300;
      font-size: 3.2rem;
      max-width: 42.5rem;
      width: 100%;
      text-align: center;
      color: rgb(168, 74, 34);
      @media (min-width: 800px) {
        text-align: initial;
        font-size: 3rem;
      }
    }
  }
}
</style>
